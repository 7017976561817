import * as React from "react";
import * as style from "./Title.style";
import { Icon, ContactModal } from "../../../components";
import scrollToElement from "scroll-to-element";
import { hooks } from "../../../utility";
import { animated, useSpring } from "react-spring";

const Title: React.FC = () => {
  const scrollToAbout = () => {
    const productElement = document.getElementById("about");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -50,
      });
    }
  };

  const scrollToOfferings = () => {
    const productElement = document.getElementById("offerings");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -50,
      });
    }
  };

  const scrollToPortfolio = () => {
    const productElement = document.getElementById("portfolio");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -50,
      });
    }
  };
  const baseSpring = {
    config: { tension: 280, friction: 130 },
    delay: 1000,
  };
  const spring1 = useSpring({
    ...baseSpring,
    from: { opacity: 0, transform: "translateX(-50px)" },
    to: {
      opacity: 1,
      transform: `translateX(0%)`,
    },
  });
  const spring2 = useSpring({
    ...baseSpring,

    from: { opacity: 0, transform: "translateX(-100px)" },
    to: {
      opacity: 1,
      transform: `translateX(0%)`,
    },
    delay: 1250,
  });
  const spring3 = useSpring({
    ...baseSpring,

    from: { opacity: 0, transform: "translateX(-150px)" },
    to: {
      opacity: 1,
      transform: `translateX(0%)`,
    },
    delay: 1500,
  });
  const spring4 = useSpring({
    ...baseSpring,

    from: { opacity: 0, transform: "translateX(-200px)" },
    to: {
      opacity: 1,
      transform: `translateX(0%)`,
    },
    delay: 1750,
  });
  const display = hooks.useDisplayContext();
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <div className={style.component}>
        {/* <div className={style.threejs}>
          <CapsBackground
            count={display.isMobile ? 30 : 80}
            depth={display.isMobile ? 50 : 80}
          />
        </div> */}
        <div className={style.overlay} />
        <div className={style.content}>
          <div className={style.ellipseWrapper}>
            <div className={style.titleEllipse}>
              <div className={style.titleContent}>
                <div className={style.title}>ALL CAPS</div>
                <div className={style.subTitle}>
                  Innovative Technology Solutions
                </div>
              </div>
            </div>
          </div>
          <div className={style.widgetWrapper}>
            <animated.div
              style={spring1}
              className={style.widget}
              onClick={scrollToAbout}
            >
              <div className={style.widgetIcon}>
                <Icon.Fingerprint size={30} />
              </div>
              <div>About</div>
            </animated.div>
            <animated.div
              style={spring2}
              className={style.widget}
              onClick={scrollToOfferings}
            >
              <div className={style.widgetIcon}>
                <Icon.HtmlBrackets size={30} />
              </div>
              <div>Offerings</div>
            </animated.div>
            <animated.div
              style={spring3}
              className={style.widget}
              onClick={scrollToPortfolio}
            >
              <div className={style.widgetIcon}>
                <Icon.File size={30} />
              </div>
              <div>Portfolio</div>
            </animated.div>
            <animated.div
              style={spring4}
              className={style.widget}
              onClick={() => setShowModal(true)}
            >
              <div className={style.widgetIcon}>
                <Icon.Email size={30} />
              </div>
              <div>Contact Us</div>
            </animated.div>
          </div>
        </div>
        <ContactModal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
        />
      </div>
    </>
  );
};

Title.displayName = "Title";
export { Title };
