import * as React from "react";
import * as style from "./Portfolio.style";
import { classes } from "typestyle";
import { hooks } from "../../../utility";
import { animated, useSpring } from "react-spring";
import octolounge from "../../../assets/octolounge.png";
import carey from "../../../assets/carey.png";
import delorenzo from "../../../assets/delorenzo.jpg";

const Portfolio: React.FC = () => {
  const display = hooks.useDisplayContext();
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const dataRef = hooks.useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  });
  const triggerRef2 = React.useRef<HTMLDivElement>(null);
  const dataRef2 = hooks.useIntersectionObserver(triggerRef2, {
    freezeOnceVisible: true,
  });
  const triggerRef3 = React.useRef<HTMLDivElement>(null);
  const dataRef3 = hooks.useIntersectionObserver(triggerRef3, {
    freezeOnceVisible: true,
  });
  const spring1 = useSpring({
    config: { tension: 280, friction: 130 },
    from: { opacity: 0, transform: "translateX(20%)" },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      transform: `translateX(${dataRef?.isIntersecting ? "0" : "20"}%)`,
    },
    delay: 500,
  });
  const spring2 = useSpring({
    config: { tension: 280, friction: 130 },
    from: { opacity: 0, transform: "translateX(20%)" },
    to: {
      opacity: dataRef2?.isIntersecting ? 1 : 0,
      transform: `translateX(${dataRef2?.isIntersecting ? "0" : "20"}%)`,
    },
    delay: 500,
  });
  const spring3 = useSpring({
    config: { tension: 280, friction: 130 },
    from: { opacity: 0, transform: "translateX(20%)" },
    to: {
      opacity: dataRef3?.isIntersecting ? 1 : 0,
      transform: `translateX(${dataRef3?.isIntersecting ? "0" : "20"}%)`,
    },
    delay: 500,
  });
  return (
    <>
      <div className={style.component} id="portfolio">
        <div className={style.header}>
          <div className={style.title}>Portfolio</div>
          <div className={style.headerSubTitle}>
            See a showcase of our most recent{" "}
            <span className={style.headerSubTitleAccent}>web development</span>{" "}
            and <span className={style.headerSubTitleAccent}>branding</span>{" "}
            projects.
          </div>
        </div>
        <div className={style.wrapper}>
          <animated.div style={spring1} className={style.item}>
            <div ref={triggerRef} />
            <a href="https://www.chrisdelorenzophoto.com" target="_blank">
              <div className={classes(style.image, style.goc)}>
                {/* <img
                  src={delorenzo}
                  alt="Chris Delorenzo Photography Logo"
                  loading="lazy"
                  width={display.isMobile ? 170 : 213}
                  height={display.isMobile ? 160 : 201}
                /> */}
              </div>
            </a>
            <div className={style.itemContent}>
              <div className={style.subTitle}>Photography</div>
              <div className={style.name}>Chris Delorenzo Photography</div>
              <div className={style.text}>
                Chris DeLorenzo is a professional photographer specializing in
                the automobile industry. With a portfolio including not only
                clients such as BMW, Lexus, and Jeep, but also personal work
                with nature and lifestyle photography - Chris needed a website
                that highlighted the depth and breadth of his work, as well as
                his personal style. Chris brought us his own design and vision -
                including different gallery modes, unique transitions, and video
                elements, and asked us to bring it to life.
              </div>
              <div>
                <a
                  href="chrisdelorenzophoto.com"
                  target="_blank"
                  className={style.link}
                >
                  Visit Site &#8594;
                </a>
              </div>
            </div>
          </animated.div>
          <animated.div style={spring2} className={style.item}>
            <div ref={triggerRef2} />
            <a href="http://www.theoctolounge.com" target="_blank">
              <div className={classes(style.image, style.octolounge)}>
                <img
                  src={octolounge}
                  alt="Octolounge Logo"
                  loading="lazy"
                  width={display.isMobile ? 240 : 280}
                  height={display.isMobile ? 128 : 150}
                />
              </div>
            </a>
            <div className={style.itemContent}>
              <div className={style.subTitle}>Charter Boat</div>
              <div className={style.name}>The Octolounge</div>
              <div className={style.text}>
                The Octolounge is a private boat charter experience in Southern
                Delaware. They are one of many in the area, and booking cruises
                is a competitive market. ALL CAPS was able to design and build a
                website that stands out not only to visitors, but also to
                Google. Our SEO-focused implementation brought The Octolounge
                from the third page of Google to a top 3 spot on a number of
                crucial search terms, driving a massive 120% increase in
                bookings year over year.
              </div>
              <div>
                <a
                  href="http://www.theoctolounge.com"
                  target="_blank"
                  className={style.link}
                >
                  Visit Site &#8594;
                </a>
              </div>
            </div>
          </animated.div>
          <animated.div style={spring3} className={style.item}>
            <div ref={triggerRef3} />
            <a href="https://www.careysfinefoods.com" target="_blank">
              <div className={classes(style.image, style.carey)}>
                <img
                  src={carey}
                  alt="Careys Fine Foods Logo"
                  loading="lazy"
                  width={display.isMobile ? 240 : 280}
                  height={display.isMobile ? 105 : 120}
                />
              </div>
            </a>
            <div className={style.itemContent}>
              <div className={style.subTitle}>
                Catering &amp; Online Snack Shop
              </div>
              <div className={style.name}>Carey's Fine Foods</div>
              <div className={style.text}>
                Carey's Fine Foods is a high-end catering company from
                Baltimore, famous for their spicy bourbon bar pretzels. When
                these pretzels found their way into the hands of an NBC
                producer, Carey was offered a feature highlight on the Today
                Show. Carey needed a brand new custom website with a fully
                functioning eCommerce integration designed and online in under 3
                weeks. After being refused by several other consulting firms due
                to the short timeline, she reached out to ALL CAPS. We were able
                to meet the short turnaround time, and successfully handle
                $20,000 worth of traffic and orders within 8 hours of the Today
                Show segment airing.
              </div>
              <div>
                <a
                  href="https://www.careysfinefoods.com"
                  target="_blank"
                  className={style.link}
                >
                  Visit Site &#8594;
                </a>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
};

Portfolio.displayName = "Portfolio";
export { Portfolio };
