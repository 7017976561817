import * as React from "react";
import * as style from "./Offer.style";
import { classes } from "typestyle";
import { hooks } from "../../../utility";
import { animated, useSpring } from "react-spring";
import delSmallBizWeb from "../../../assets/delaware-small-business-websites.jpg";
import delSmallBizWebMobile from "../../../assets/delaware-small-business-website-mobile.png";
import design from "../../../assets/design.png";
import rocket from "../../../assets/rocket.png";
import seo from "../../../assets/seo.png";
import analytics from "../../../assets/analytics.png";
import shopify from "../../../assets/logos/delaware-shopify-site-integration.png";
import square from "../../../assets/logos/square-integration.png";
import seoLogo from "../../../assets/logos/delaware-seo-service.png";
import photoshop from "../../../assets/logos/delaware-photoshop-services.png";
import aws from "../../../assets/logos/aws-hosting-services.png";
import google from "../../../assets/logos/google-analytics.png";

const Offer: React.FC = () => {
  const display = hooks.useDisplayContext();
  const imgTriggerRef = React.useRef<HTMLDivElement>(null);
  const imgDataRef = hooks.useIntersectionObserver(imgTriggerRef, {
    freezeOnceVisible: true,
  });
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const dataRef = hooks.useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  });
  const direction = display.isMobile ? "X" : "Y";

  const imgSpring = useSpring({
    config: { duration: 2000 },
    from: { opacity: 0 },
    to: {
      opacity: imgDataRef?.isIntersecting ? 1 : 0,
    },
    delay: 500,
  });

  const spring1 = useSpring({
    config: { tension: 280, friction: 130 },
    from: { opacity: 0, transform: `translate${direction}(-200px)` },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      transform: `translate${direction}(${
        dataRef?.isIntersecting ? "0" : "-200"
      }px)`,
    },
    delay: 500,
  });
  const spring2 = useSpring({
    config: { tension: 280, friction: 130 },
    from: { opacity: 0, transform: `translate${direction}(200px)` },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      transform: `translate${direction}(${
        dataRef?.isIntersecting ? "0" : "200"
      }px)`,
    },
    delay: 500,
  });
  return (
    <>
      <div className={style.component} id="offerings">
        <div className={style.wrapper}>
          <animated.div style={imgSpring} className={style.imageContainer}>
            <div ref={imgTriggerRef} />
            <img
              className={style.laptopImage}
              src={delSmallBizWeb}
              alt="Delaware Small Business Website"
              loading="lazy"
              width={display.viewport === "md" ? 800 : 1024}
            />
            <img
              className={style.mobileImage}
              src={delSmallBizWebMobile}
              alt="Delaware Small Business Mobile Website"
              loading="lazy"
              height={420}
            />
          </animated.div>
          <div ref={triggerRef}>
            <div className={style.content}>
              <animated.div style={spring1} className={style.item}>
                <div className={classes(style.icon, style.blueBackground)}>
                  <img
                    src={design}
                    alt="Design"
                    loading="lazy"
                    width={display.isMobile ? 60 : 125}
                    height={display.isMobile ? 80 : 167}
                  />
                </div>
                <div className={style.text}>
                  <div className={style.title}>Custom Designs</div>
                  <div>
                    All of our designs are one of a kind, custom, and catered to
                    our clients' needs. We pride ourselves in creating smart,
                    modern user interfaces and branding that will impress.
                  </div>
                </div>
              </animated.div>
              <animated.div style={spring2} className={style.item}>
                <div className={style.icon}>
                  <img
                    src={rocket}
                    alt="Rocket"
                    loading="lazy"
                    width={display.isMobile ? 60 : 125}
                    height={display.isMobile ? 80 : 167}
                  />
                </div>
                <div className={style.text}>
                  <div className={style.title}>Speed</div>
                  <div>
                    As a small business, we are able to bring your ideas to
                    fruition efficiently. We can provide fast-paced,
                    fully-realized solutions on your timeline - something you
                    won't find elsewhere in the software consulting industry.
                  </div>
                </div>
              </animated.div>
              <animated.div style={spring1} className={style.item}>
                <div className={classes(style.icon, style.blueBackground)}>
                  <img
                    src={seo}
                    alt="SEO"
                    loading="lazy"
                    width={display.isMobile ? 60 : 125}
                    height={display.isMobile ? 80 : 167}
                  />
                </div>
                <div className={style.text}>
                  <div className={style.title}>SEO</div>
                  <div>
                    We build modern websites that are optimized for performance
                    and SEO, ensuring high-ranking search results and seamless
                    experiences for your users.
                  </div>
                </div>
              </animated.div>
              <animated.div style={spring2} className={style.item}>
                <div className={style.icon}>
                  <img
                    src={analytics}
                    alt="Analytics"
                    loading="lazy"
                    width={display.isMobile ? 60 : 125}
                    height={display.isMobile ? 80 : 167}
                  />
                </div>
                <div className={style.text}>
                  <div className={style.title}>Analytics</div>
                  <div>
                    We can provide you with the analytics you need to improve
                    your business - insights into site performance, user visits,
                    clicks, time on site, and more.
                  </div>
                </div>
              </animated.div>
            </div>
          </div>
          {!display.isMobile && (
            <div className={style.icons}>
              <img
                src={shopify}
                alt="Delaware Shopify Website Integration"
                loading="lazy"
                className={style.logo}
              />
              <img
                src={square}
                alt="Square Integration"
                loading="lazy"
                className={style.logo}
              />
              <img
                src={seoLogo}
                alt="Delaware SEO Services"
                loading="lazy"
                className={style.logo}
              />
              <img
                src={photoshop}
                alt="Delaware Photoshop Services"
                loading="lazy"
                className={style.logo}
              />
              <img
                src={aws}
                alt="AWS Hosting"
                loading="lazy"
                className={style.logo}
              />
              <img
                src={google}
                alt="Google Analytics"
                loading="lazy"
                className={style.logo}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Offer.displayName = "Offer";
export { Offer };
