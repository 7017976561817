import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    background: Style.colors.white3,
    fontFamily: Style.text.font.third,
    position: "relative",
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/Allcaps/web-development-services-delaware.jpg)",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      backgroundImage: "none",
    },
  ),
);

export const wrapper = s(
  {
    maxWidth: 1100,
    display: "flex",
    margin: "auto",
    padding: `30px 15px`,
    background: "rgba(255, 255, 255, .85)",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: "75px 100px",
      background: "none",
    },
  ),
);

export const image = s({
  height: 445,
  marginRight: 70,
});

export const content = s({
  flex: 1,
});

export const title = s({
  fontWeight: 600,
  color: Style.text.color.main,
  marginBottom: 25,
  fontSize: 36,
});

export const subTitle = s({
  color: Style.text.color.subtle,
  marginBottom: 10,
  fontSize: 14,
  textTransform: "uppercase",
});

export const text = s({
  fontSize: Style.text.fontSize.small,
  color: Style.text.color.dark,
  lineHeight: "32px",
  fontWeight: 400,
  marginBottom: 25,
});

export const button = s({
  fontSize: 18,
  textTransform: "uppercase",
  letterSpacing: "1px",
  fontFamily: Style.text.font.third,
  fontWeight: 600,
});
