import * as React from "react";
import * as style from "./About.style";
import { Button, ContactModal } from "../../../components";
import { hooks } from "../../../utility";
import { animated, config, useSpring } from "react-spring";
import bg from "../../../assets/web-development-services-delaware.jpg";

const About: React.FC = () => {
  const display = hooks.useDisplayContext();
  const [showModal, setShowModal] = React.useState(false);
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const dataRef = hooks.useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  });
  const spring1 = useSpring({
    config: { tension: 280, friction: 130 },
    from: { opacity: 0, transform: "translateX(20%)" },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      transform: `translateX(${dataRef?.isIntersecting ? "0" : "20"}%)`,
    },
    delay: 200,
  });
  const spring2 = useSpring({
    config: { tension: 280, friction: 130 },
    from: { opacity: 0, transform: "translateX(40%)" },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      transform: `translateX(${dataRef?.isIntersecting ? "0" : "40"}%)`,
    },
    delay: 400,
  });
  const spring3 = useSpring({
    config: { tension: 280, friction: 130 },
    from: { opacity: 0, transform: "translateX(60%)" },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      transform: `translateX(${dataRef?.isIntersecting ? "0" : "60"}%)`,
    },
    delay: 600,
  });
  return (
    <>
      <div className={style.component} id="about">
        <div className={style.wrapper}>
          {!display.isMobile && (
            <img
              className={style.image}
              src={bg}
              alt="Delaware Web Development Services"
            />
          )}
          <div className={style.content}>
            <animated.div style={spring1} className={style.subTitle}>
              From Delaware to Nationwide
            </animated.div>
            <animated.div style={spring2} className={style.title}>
              Specializing in Web Development
            </animated.div>
            <div ref={triggerRef} />
            <animated.div style={spring3} className={style.text}>
              <p>
                ALL CAPS Technology is a software consulting firm based out of
                Rehoboth Beach, Delaware. We are a collective of software
                engineers, web designers, SEO experts, photographers,
                videographers, data engineers, and graphic designers. We are
                here to meet you and your company's technology needs, on your
                schedule.
              </p>
              <p>
                Our services include anything from designing and building your
                website from scratch, creating your logo and other digital
                assets along the way, or just adding a new feature to your
                existing project when your team doesn't have the time. We also
                provide SEO services, data management solutions, create 3D
                virtual tours, and more!
              </p>
            </animated.div>
            <Button
              rounded={false}
              className={style.button}
              onClick={() => setShowModal(true)}
            >
              Get To Know Us
            </Button>
          </div>
        </div>
        <ContactModal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
        />
      </div>
    </>
  );
};

About.displayName = "About";
export { About };
