import { style } from "typestyle";
import * as Style from "../../styles";

export const component = style({
  background:
    "linear-gradient(180deg, #5C7E96 0%, rgba(92, 126, 150, 0.87) 100%)",
  whiteSpace: "nowrap",
  $nest: {
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: Style.colors.second,
    },
  },
  transition: ".2s ease-in",
  textDecoration: "none",
  outline: "none",
  color: Style.colors.white,
  padding: `12px 18px`,
  fontSize: Style.text.fontSize.medium,
  borderRadius: "2px",
  border: "none",
  cursor: "pointer",
});

export const dark = style({
  backgroundColor: Style.colors.black1,
  borderColor: Style.colors.translucent150,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent650,
    },
  },
  background: "none",
});

export const rounded = style({
  borderRadius: 16,
});

export const disabled = style({
  backgroundColor: "#333333",
  color: "rgba(255, 255, 255, .5)",
  background: "none",
  cursor: "auto",
  $nest: {
    "&:hover": {
      backgroundColor: "#333333",
    },
  },
});
