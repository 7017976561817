import * as React from "react";
import { IKImage } from "imagekitio-react";
import scrollToElement from "scroll-to-element";
import * as style from "./MobileHeader.style";
import { Burger } from "../Burger";
import { colors } from "../../styles";
import { Modal } from "../Modal";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
}

/**
 * Header component
 * Page Header
 */
export const MobileHeader: React.FC<ComponentProps> = ({ className }) => {
  const [isAtTop, setIsAtTop] = React.useState(true);
  const [menuOpen, setMenuOpen] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset === 0 && !isAtTop) {
        setIsAtTop(true);
      } else if (window.pageYOffset > 0 && isAtTop) {
        setIsAtTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isAtTop]);

  const scrollToWhoWeAre = () => {
    const productElement = document.getElementById("whoWeAre");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -200,
      });
    }
    setMenuOpen(false);
  };

  const scrollToProducts = () => {
    const productElement = document.getElementById("products");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -200,
      });
    }
    setMenuOpen(false);
  };

  const scrollToVision = () => {
    const productElement = document.getElementById("vision");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -200,
      });
    }
    setMenuOpen(false);
  };

  const scrollToTeam = () => {
    const productElement = document.getElementById("team");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -50,
      });
    }
    setMenuOpen(false);
  };

  const scrollToTop = () => {
    const headerElement = document.getElementById("home");
    if (headerElement) {
      scrollToElement(headerElement, {
        duration: 1000,
        offset: -50,
      });
    }
    setMenuOpen(false);
  };

  const scrollToFeatures = () => {
    const productElement = document.getElementById("features");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -200,
      });
    }
    setMenuOpen(false);
  };

  return (
    <>
      <div className={style.component} id="header">
        <div onClick={scrollToTop}>
          {/* <IKImage
            path={`/GOC/logo.png`}
            alt="GOC logo"
            className={style.logo}
          /> */}
        </div>
        <Burger
          isOpen={menuOpen}
          onClick={() => setMenuOpen(!menuOpen)}
          color={colors.second}
          className={style.burger}
        />
      </div>

      <Modal
        isOpen={menuOpen}
        onRequestClose={() => setMenuOpen(false)}
        className={style.modal}
        showCloseButton={false}
      >
        <div className={style.navItem} onClick={scrollToWhoWeAre}>
          Who we are
        </div>
        <div className={style.navItem} onClick={scrollToProducts}>
          Our Products
        </div>
        <div className={style.navItem} onClick={scrollToFeatures}>
          Features &amp; Amenities
        </div>
        <div className={style.navItem} onClick={scrollToVision}>
          Our Vision
        </div>
        <div className={style.navItem} onClick={scrollToTeam}>
          Our Team
        </div>
      </Modal>
    </>
  );
};

MobileHeader.displayName = "MobileHeader";
