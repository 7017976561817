import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  position: "fixed",
  padding: `7px 10px 0px`,
  top: 0,
  left: 0,
  right: 0,
  height: 70,
  zIndex: 10,
  backgroundColor: Style.colors.white,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const logo = s({
  width: 61,
  height: 57,
});

export const menu = s({
  width: 33,
  height: 50,
});

export const modal = s({
  background: Style.colors.third,
  opacity: 0.8,
  padding: "120px 70px",
});

export const burger = s({
  zIndex: 1010,
});

export const navItem = s({
  fontSize: Style.text.fontSize.large,
  textTransform: "uppercase",
  marginBottom: 20,
  textAlign: "center",
  fontWeight: 300,
  color: Style.text.color.inverse,
  $nest: {
    "&:hover": {
      cursor: "pointer",
      fontWeight: 400,
      color: Style.text.color.inverseSubtle,
    },
  },
  transition: ".3s ease-out",
});
