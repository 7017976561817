import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    textAlign: "center",
    padding: 10,
    position: "relative",
    color: Style.colors.white,
    background:
      "radial-gradient(41.63% 41.63% at 50% 50%, rgba(0, 0, 0, 0.7387) 0%, rgba(0, 11, 32, 0.843646) 2.6%, rgba(0, 7, 20, 0.89) 100%)",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 24,
    },
  ),
);

export const logo = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 10,
    },
  ),
);

export const text = s(
  {
    fontSize: Style.text.fontSize.xs,
    fontWeight: 400,
    fontFamily: Style.text.font.main,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.small,
    },
  ),
);
