import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const modal = s(
  {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    fontFamily: Style.text.font.main,
  },
  media(
    { minWidth: Style.screensize.smMin },
    {
      top: 0,
    },
  ),
  media(
    { minWidth: Style.screensize.mdMin },
    {
      top: 50,
      left: 100,
      right: 100,
      bottom: 100,
    },
  ),
);

export const close = s({
  position: "absolute",
  top: 15,
  right: 15,
  zIndex: 50,
  cursor: "pointer",
  $nest: {
    "& svg": {
      fontSize: 25,
      fontWeight: 700,
      transition: ".2s ease-in",
      $nest: {
        "&:hover": {
          fill: Style.colors.translucent900,
          stroke: Style.colors.translucent900,
        },
      },
    },
  },
});

export const contentWrapper = s({
  overflow: "auto",
  height: "100%",
  $nest: {
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: "#F5F5F5",
      borderRadius: "0px 4px 4px 0px",
      width: 8,
    },
    "&::-webkit-scrollbar": {
      width: 8,
      borderRadius: "0px 4px 4px 0px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "0px 4px 4px 0px",
      backgroundImage: `-webkit-gradient(linear,left bottom,left top,color-stop(0.44, #5E788C),color-stop(0.86, #7B9DB7))`,
      width: 8,
    },
  },
});
