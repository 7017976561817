// prettier-ignore
import { createBrowserHistory } from "history";
import * as React from "react";
import { Route, Router } from "react-router-dom";
import { Header, Footer } from "../components";
import { IKContext } from "imagekitio-react";
import { DisplayContextProvider } from "./DisplayContext";
import { useSpring, animated } from "react-spring";
import { style as s } from "typestyle";
import { Home } from "../pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const component = s({
  width: "100%",
  height: "100%",
  zIndex: 1,
  overflow: "hidden",
});

const Page: React.FC = () => {
  return (
    <div className={component}>
      {/* <Header /> */}
      <Route strict exact={true} path="/" component={Home} />
      <Footer />
    </div>
  );
};

const history = createBrowserHistory();

export const App = () => {
  const spring = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  return (
    <animated.div style={{ ...spring, position: "relative" }}>
      <Router history={history}>
        <DisplayContextProvider>
          {/* <IKContext urlEndpoint="https://ik.imagekit.io/yd113v8urnw4/Allcaps"> */}
          <Page />
          {/* </IKContext> */}
        </DisplayContextProvider>
        <ToastContainer
          autoClose={5000}
          position="top-right"
          newestOnTop={false}
          hideProgressBar={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </Router>
    </animated.div>
  );
};
