import { media, style as s } from "typestyle";
import * as Style from "../../styles";

const family = "Oswald";

export const component = s({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  fontFamily: Style.text.font.main,
});

export const form = s({
  background:
    "radial-gradient(41.63% 41.63% at 50% 50%, rgba(0, 0, 0, 0.7387) 0%, rgba(0, 11, 32, 0.843646) 0.01%, rgba(0, 7, 20, 0.89) 60.01%)",
});
