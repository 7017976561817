import * as React from "react";
import { IKImage } from "imagekitio-react";
import { classes } from "typestyle";
import scrollToElement from "scroll-to-element";
import * as style from "./Header.style";
import { ContactModal } from "../ContactModal";
import { MobileHeader } from "./MobileHeader";
import { hooks } from "../../utility";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
}

/**
 * Header component
 * Page Header
 */
export const Header: React.FC<ComponentProps> = ({ className }) => {
  const display = hooks.useDisplayContext();
  const [isAtTop, setIsAtTop] = React.useState(true);
  const [contactOpen, setContactOpen] = React.useState(false);
  React.useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset === 0 && !isAtTop) {
        setIsAtTop(true);
      } else if (window.pageYOffset > 0 && isAtTop) {
        setIsAtTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isAtTop]);

  const scrollToWhoWeAre = () => {
    const productElement = document.getElementById("whoWeAre");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -200,
      });
    }
  };

  const scrollToProducts = () => {
    const productElement = document.getElementById("products");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -200,
      });
    }
  };

  const scrollToFeatures = () => {
    const productElement = document.getElementById("features");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -200,
      });
    }
  };

  const scrollToVision = () => {
    const productElement = document.getElementById("vision");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -200,
      });
    }
  };

  const scrollToTeam = () => {
    const productElement = document.getElementById("team");
    if (productElement) {
      scrollToElement(productElement, {
        duration: 1000,
        offset: -50,
      });
    }
  };

  const scrollToTop = () => {
    const headerElement = document.getElementById("home");
    if (headerElement) {
      scrollToElement(headerElement, {
        duration: 1000,
        offset: -50,
      });
    }
  };

  return display.isMobile ? (
    <MobileHeader />
  ) : (
    <>
      <div
        className={classes(
          className,
          isAtTop && style.componentTop,
          style.component,
        )}
        id="header"
      >
        <div className={style.sectionLeft}>
          <div className={style.navItem} onClick={scrollToWhoWeAre}>
            Who we are
          </div>
          <div className={style.navItem} onClick={scrollToProducts}>
            Our Products
          </div>
          <div className={style.navItem} onClick={scrollToFeatures}>
            Features &amp; Amenities
          </div>
        </div>
        <div onClick={scrollToTop}>
          {/* <IKImage
            path={`/GOC/logo.png`}
            alt="GOC logo"
            className={classes(isAtTop && style.logoTop, style.logo)}
          /> */}
        </div>
        <div className={style.sectionRight}>
          <div className={style.navItem} onClick={scrollToVision}>
            Our Vision
          </div>
          <div className={style.navItem} onClick={scrollToTeam}>
            Our Team
          </div>

          <div
            className={style.contactWrapper}
            onClick={() => setContactOpen(true)}
          >
            <div className={classes(style.navItem, style.contact)}>Contact</div>
          </div>
        </div>
      </div>
      <ContactModal
        isOpen={contactOpen}
        onRequestClose={() => {
          setContactOpen(false);
        }}
      />
    </>
  );
};

Header.displayName = "Header";
