import * as colors from "./colors";

export const fontSize = {
  /** 10 */
  xxxs: 10,
  /** 12 */
  xxs: 12,
  /** 14 */
  xs: 14,
  /** 16 */
  small: 16,
  /** 20 */
  smallMed: 20,
  /** 24 */
  medium: 24,
  /** 28 */
  medLarge: 28,
  /** 32 */
  large: 32,
  /** 40 */
  xl: 40,
  /** 48 */
  xxl: 48,
  /** 58 */
  xxxl: 58,
};

export const color = {
  main: "#071842",
  subtle: "#688BA4",
  dark: "#071842",
  inverse: colors.white2,
  inverseSubtle: colors.black4,
};

export const font = {
  /** Poppins */
  main: "Poppins",
  /** Paytone One */
  second: "Paytone One",
  /** Manrope */
  third: "Manrope",
};
