import * as React from "react";
import * as style from "./Home.style";
import { Title } from "./Title";
import { About } from "./About";
import { Offer } from "./Offer";
import { Portfolio } from "./Portfolio";
import { ContactForm } from "../../components";

const Home: React.FC = () => {
  return (
    <>
      <div className={style.component}>
        <Title />
        <About />
        <Offer />
        <Portfolio />
        <ContactForm className={style.form} />
      </div>
    </>
  );
};

Home.displayName = "Home";
export { Home };
