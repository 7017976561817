import * as React from "react";
import * as style from "./Footer.style";
import { classes } from "typestyle";
import { hooks } from "../../utility";
import { LogoWhite } from "../Icon";
import * as Style from "../../styles";

export interface ComponentProps {
  className?: string;
}

const Footer: React.FC<ComponentProps> = ({ className }) => {
  const display = hooks.useDisplayContext();
  return (
    <div className={classes(className, style.component)}>
      <div className={style.text}>
        &copy; 2021 All Caps Technology. All Rights Reserved
      </div>
    </div>
  );
};

Footer.displayName = "Footer";
export { Footer };
