import { media, style as s } from "typestyle";
import * as Style from "../../../styles";
import bg from "../../../assets/delaware-website-building-services.png";

export const component = s({
  textAlign: "center",
  position: "relative",
  background: `url("${bg}")`,
  minHeight: 750,
});

export const overlay = s({
  position: "absolute",
  zIndex: 1,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  background:
    "radial-gradient(41.63% 41.63% at 50% 50%, rgba(0, 0, 0, 0.7387) 0%, rgba(0, 11, 32, 0.843646) 0.01%, rgba(0, 7, 20, 0.89) 60.01%)",
});

export const threejs = s(
  {
    height: "105vh",
    width: "100%",
    zIndex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      height: 900,
    },
  ),
);

export const content = s({
  position: "absolute",
  width: "100%",
  top: 100,
  textAlign: "center",
  zIndex: 2,
});

export const ellipseWrapper = s(
  {
    background: "rgb(0, 11, 32)",
    width: 200,
    height: 200,
    margin: `0px auto 120px`,
    borderRadius: "50%",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: 320,
      height: 320,
      margin: `0px auto 60px`,
    },
  ),
);

export const titleEllipse = s(
  {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: Style.text.color.inverse,
    width: 200,
    height: 200,
    borderRadius: "50%",
    background:
      "linear-gradient(180deg, rgba(196, 196, 196, 0.113311) 29.17%, rgba(196, 196, 196, 0.13) 46.35%, rgba(196, 196, 196, 0) 46.36%, rgba(196, 196, 196, 0.0169) 65.1%, rgba(196, 196, 196, 0.112396) 100%)",
    boxShadow: "0px 4px 10px 4px #00000040",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: 320,
      height: 320,
    },
  ),
);

export const titleContent = s(
  {
    padding: "40px 0px",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: `67px 45px`,
    },
  ),
);

export const title = s(
  {
    fontFamily: Style.text.font.second,
    textTransform: "uppercase",
    fontSize: Style.text.fontSize.medLarge,
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.xl,
      marginBottom: 67,
    },
  ),
);

export const subTitle = s(
  {
    fontFamily: Style.text.font.main,
    fontSize: Style.text.fontSize.xxs,
    textTransform: "uppercase",
    fontWeight: 200,
    color: Style.text.color.inverseSubtle,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.small,
    },
  ),
);

export const widgetWrapper = s(
  {
    display: "flex",
    justifyContent: "space-around",
    maxWidth: 360,
    // paddingTop: "50vh",
    margin: "auto",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      // paddingTop: 420,
      maxWidth: 550,
    },
  ),
);

export const widget = s(
  {
    width: 80,
    height: 80,
    backgroundColor: Style.colors.black2,
    boxSizing: "border-box",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontSize: Style.text.fontSize.xxs,
    transition: ".3s ease-out",
    color: Style.text.color.inverseSubtle,
    $nest: {
      "&:hover": {
        backgroundColor: Style.colors.darkTranslucent300,
        cursor: "pointer",
      },
    },
    zIndex: 2,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: 115,
      height: 105,
      fontSize: Style.text.fontSize.xs,
    },
  ),
);

export const widgetIcon = s(
  {
    width: 30,
    height: 30,
    marginBottom: 10,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 15,
    },
  ),
);
