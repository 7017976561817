import * as React from "react";
import * as style from "./ContactForm.style";
import { Button, TextInput } from "../../components";
import { hooks } from "../../utility";
import { classes } from "typestyle";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";

const SERVICE_ID = "service_c8mq82g";
const TEMPLATE_ID = "template_c9v2js9";
const USER_ID = "user_BYHTvWHdurWUyvjL7ZRHK";
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

interface ComponentProps {
  className?: string;
  onSuccess?: () => void;
}

const ContactForm: React.FC<ComponentProps> = ({ className, onSuccess }) => {
  const display = hooks.useDisplayContext();

  const [name, setName] = React.useState<string | undefined>();
  const [email, setEmail] = React.useState<string | undefined>();
  const [mobile, setMobile] = React.useState<string | undefined>();
  const [company, setCompany] = React.useState<string | undefined>();
  const [message, setMessage] = React.useState<string | undefined>();

  const [nameError, setNameError] = React.useState<string | undefined>();
  const [emailError, setEmailError] = React.useState<string | undefined>();
  const [mobileError, setMobileError] = React.useState<string | undefined>();
  const [messageError, setMessageError] = React.useState<string | undefined>();

  const onSubmit = () => {
    if (!name) {
      setNameError("Please enter your name");
    }
    if (!email) {
      setEmailError("Please enter your email");
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
    }
    if (!!mobile && mobile.length < 10) {
      setMobileError(
        "Please provide a valid mobile number, or leave this field blank",
      );
    }
    if (!message) {
      setMessageError("Please enter your name");
    }
    if (
      !!name &&
      !!email &&
      emailRegex.test(email) &&
      (!mobile || mobile.length === 10) &&
      !!message
    ) {
      emailjs
        .send(
          SERVICE_ID,
          TEMPLATE_ID,
          { name, email, mobile, company, message },
          USER_ID,
        )
        .then(
          (response) => {
            toast.success(
              "Thank you for reaching out! We will get back to you shortly.",
              { className: style.toast },
            );
            setName("");
            setMobile("");
            setEmail("");
            setMessage("");
            setCompany("");
            if (onSuccess) {
              onSuccess();
            }
          },
          (error) => {
            toast.warning(
              "Something seems to have gone wrong - please refresh the page and try again",
            );
          },
        );
    }
  };

  return (
    <div className={classes(style.component, className)}>
      <div className={style.content}>
        <div className={style.subTitle}>Interested in working together?</div>
        <div className={style.title}>
          Need an agency-caliber website delivered fast? Reach out now, that's{" "}
          <span
            style={{
              fontStyle: "italic",
              fontWeight: 800,
              color: "#7B9DB7",
              marginRight: 2,
            }}
          >
            our
          </span>{" "}
          speciality.
        </div>
        <div className={style.inputSection}>
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.input}
            label="Name"
            value={name}
            onChange={(val?: string) => {
              setName(val);
              if (!val) {
                setNameError("Please enter your name");
              } else {
                setNameError("");
              }
            }}
            onBlur={() => {
              if (!name) {
                setNameError("Please enter your name");
              }
            }}
            error={nameError}
          />
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.input}
            label="Email"
            value={email}
            onChange={(val: string) => {
              setEmail(val);
              if (!val) {
                setEmailError("Please enter your email");
              } else {
                setEmailError("");
              }
            }}
            onBlur={() => {
              if (!email) {
                setEmailError("Please enter your email");
              } else if (!emailRegex.test(email)) {
                setEmailError("Please enter a valid email");
              }
            }}
            inputType="email"
            error={emailError}
          />
        </div>
        <div className={style.inputSection}>
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.input}
            label="Mobile (optional)"
            value={mobile}
            onChange={(val: string) => {
              if (/^[0-9]*$/.test(val) && val.length < 11) {
                setMobile(val);
                setMobileError("");
              }
            }}
            onBlur={() => {
              if (!!mobile && mobile.length < 10) {
                setMobileError(
                  "Please provide a 10-digit mobile number, or leave this field blank",
                );
              }
            }}
            inputType="tel"
            error={mobileError}
          />
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.input}
            label="Company (optional)"
            value={company}
            onChange={(val: string) => setCompany(val)}
          />
        </div>
        <div className={style.inputSection}>
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.messageInput}
            label="Message"
            value={message}
            onChange={(val: string) => {
              setMessage(val);
              if (!val) {
                setMessageError(
                  "Please enter some information about why you are reaching out",
                );
              } else {
                setMessageError("");
              }
            }}
            onBlur={() => {
              if (!message) {
                setMessageError(
                  "Please enter some information about why you are reaching out",
                );
              }
            }}
            error={messageError}
            isTextArea={true}
          />
        </div>
        <div>
          <Button
            className={style.button}
            rounded={false}
            disabled={
              !!nameError || !!emailError || !!mobileError || !!messageError
            }
            onClick={onSubmit}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

ContactForm.displayName = "ContactForm";
export { ContactForm };
