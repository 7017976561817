import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    position: "fixed",
    padding: `10px 40px 0px`,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: Style.colors.translucent900,
    color: Style.text.color.main,
    fontFamily: Style.text.font.main,
    fontWeight: 300,
    fontSize: Style.text.fontSize.xs,
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    transition: "all .3s ease-out",
    $nest: {
      a: {
        color: Style.text.color.main,
      },
    },
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: Style.text.fontSize.small,
    },
  ),
  media(
    { minWidth: 1600 },
    {
      fontSize: Style.text.fontSize.smallMed,
    },
  ),
);

export const componentTop = s({
  backgroundColor: "transparent",
  color: Style.text.color.inverse,
  paddingTop: 30,
  $nest: {
    a: {
      color: Style.text.color.inverse,
    },
  },
});

export const sectionLeft = s({
  flex: 1,
  display: "flex",
  justifyContent: "space-evenly",
});

export const sectionRight = s({
  flex: 1,
  display: "flex",
  justifyContent: "space-evenly",
});

export const logo = s(
  {
    width: 99,
    height: 91,
    transition: "all .3s ease-out",
    margin: `0px 20px`,
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: 109,
      height: 100,
    },
  ),
);

export const logoTop = s(
  {
    width: 129,
    height: 120,
    transition: "all .3s ease-out",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: 150,
      height: 140,
    },
  ),
);

export const navItem = s({
  outline: "none",
  border: "none",
  textDecoration: "none",
  padding: 10,
  textAlign: "center",
  fontWeight: 300,
  $nest: {
    "&:hover": {
      cursor: "pointer",
      fontWeight: 400,
      color: Style.colors.second,
    },
  },
  transition: ".3s ease-out",
});

export const contactWrapper = s({
  textDecoration: "none",
});

export const contact = s({
  borderRadius: 5,
  border: `1px solid ${Style.colors.second}`,
  textDecoration: "none",
  $nest: {
    "&:hover": {
      border: `1px solid ${Style.colors.third}`,
    },
  },
});
