import EmailSVG from "./svgs/email.svg";
import FileSVG from "./svgs/file.svg";
import FingerprintSVG from "./svgs/fingerprint.svg";
import HtmlBracketsSVG from "./svgs/htmlBrackets.svg";
import CloseSVG from "./svgs/close.svg";
import SpinnerSVG from "./svgs/spinner.svg";
import MailBroSVG from "./svgs/mailbro.svg";
import PortfolioSVG from "./svgs/portfolio.svg";
import LogoBlackSVG from "./svgs/logoBlack.svg";
import LogoWhiteSVG from "./svgs/logoWhite.svg";
import { Icon } from "./Icon";

export const Email = Icon(EmailSVG);
export const File = Icon(FileSVG);
export const Fingerprint = Icon(FingerprintSVG);
export const HtmlBrackets = Icon(HtmlBracketsSVG);
export const Close = Icon(CloseSVG);
export const Spinner = Icon(SpinnerSVG);
export const MailBro = Icon(MailBroSVG);
export const Portfolio = Icon(PortfolioSVG);
export const LogoBlack = Icon(LogoBlackSVG);
export const LogoWhite = Icon(LogoWhiteSVG);
