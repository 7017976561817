import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    background: "#FFFFFF",
    padding: "30px 15px",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: "20px 10px",
    },
  ),
);

export const wrapper = s({
  maxWidth: 1100,
  margin: "auto",
});

export const imageContainer = s(
  {
    position: "relative",
    width: "100%",
    marginBottom: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 0,
    },
  ),
);

export const laptopImage = s(
  { width: "100%" },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: 800,
      margin: `0px auto 30px`,
    },
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: 1024,
    },
  ),
);

export const mobileImage = s(
  {
    position: "absolute",
    width: "20%",
    height: "auto",
    right: 10,
    bottom: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      height: 420,
      bottom: 40,
      right: 120,
    },
  ),
);

export const content = s(
  {
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 60,
    },
  ),
);

export const item = s(
  {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    flex: 1,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      textAlign: "center",
      marginBottom: 0,
      display: "block",
      marginRight: 20,
      $nest: {
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
  ),
);

export const icon = s(
  {
    width: 100,
    height: 100,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Style.colors.second,
    borderRadius: "50%",
    flex: "0 0 100px",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: 200,
      height: 200,
      margin: "auto",
    },
  ),
);

export const blueBackground = s({
  backgroundColor: Style.colors.third,
});

export const title = s(
  {
    fontSize: Style.text.fontSize.smallMed,
    color: "#000000",
    marginBottom: 15,
    fontWeight: 700,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 20,
      fontSize: Style.text.fontSize.medium,
    },
  ),
);

export const text = s(
  {
    fontSize: Style.text.fontSize.xxs,
    fontFamily: Style.text.font.third,
    fontWeight: 500,
    lineHeight: "20px",
    color: "#46484B",
    background: "#FDFDFD",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
    textAlign: "left",
    padding: `20px 10px`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "90%",
      marginTop: -30,
      padding: "30px 15px",
      minHeight: 280,
      fontSize: Style.text.fontSize.xs,
    },
  ),
);

export const icons = s({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  alignItems: "center",
});

export const logo = s(
  {
    maxHeight: 40,
    marginBottom: 10,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      maxHeight: 110,
    },
  ),
);
