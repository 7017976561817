import { media, style as s } from "typestyle";
import * as Style from "../../../styles";
import gobstopper from "../../../assets/gobstopper.png";
import lighthouse from "../../../assets/lighthouse.jpg";
import delorenzo from "../../../assets/delorenzo.jpg";
import portfolioBg from "../../../assets/portfolio-bg.png";

export const component = s({
  fontFamily: Style.text.font.third,
  backgroundImage: `url("${portfolioBg}")`,
  backgroundSize: "cover",
});

export const wrapper = s(
  {
    padding: "30px 15px",
    maxWidth: 1100,
    margin: "auto",
    background: "rgba(255, 255, 255, .8)",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    { background: "none", padding: "50px 130px" },
  ),
);

export const header = s(
  {
    background: "#F5F3F3",
    fontFamily: Style.text.font.main,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      alignItems: "center",
    },
  ),
);

export const title = s(
  {
    background:
      "linear-gradient(90.49deg, rgba(67, 74, 100, 0.89) -0.28%, #434A64 99.79%)",
    fontSize: Style.text.fontSize.medium,
    fontWeight: 900,
    color: "#F5F5F5",
    padding: `20px 15px`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.xxl,
      padding: "40px 65px",
    },
  ),
);

export const headerSubTitle = s(
  {
    flex: 1,
    fontWeight: 300,
    fontSize: Style.text.fontSize.small,
    padding: `10px 15px`,
    textAlign: "left",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.smallMed,
      textAlign: "center",
    },
  ),
);

export const headerSubTitleAccent = s({
  fontWeight: 500,
  color: "#5C7E96",
});

export const item = s(
  {
    display: "flex",
    flexDirection: "column",
    marginBottom: 80,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flexDirection: "row",
    },
  ),
);

export const image = s(
  {
    cursor: "pointer",
    width: 320,
    height: 240,
    marginBottom: 25,
    borderRadius: 6,
    backgroundColor: Style.colors.white,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
    transition: ".5s ease-in",
    $nest: {
      "&:hover": {
        boxShadow: `4px 8px 8px rgba(0, 0, 0, 0.45)`,
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: 380,
      height: 320,
      marginBottom: 0,
      marginRight: 30,
    },
  ),
);

export const carey = s({
  backgroundImage: `url("${gobstopper}")`,
});
export const goc = s({
  backgroundImage: `url("${delorenzo}")`,
});
export const octolounge = s({
  backgroundImage: `url("${lighthouse}")`,
});

export const itemContent = s({
  paddingTop: 20,
});

export const subTitle = s({
  fontSize: 14,
  marginBottom: 10,
  fontWeight: 600,
  textTransform: "uppercase",
  color: Style.text.color.subtle,
});

export const name = s(
  {
    fontFamily: Style.text.font.third,
    fontSize: Style.text.fontSize.medium,
    fontWeight: 600,
    marginBottom: 10,
    color: Style.text.color.dark,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 32,
      marginBottom: 15,
    },
  ),
);

export const text = s({
  fontSize: Style.text.fontSize.small,
  lineHeight: "32px",
  marginBottom: 20,
  color: Style.text.color.main,
});

export const link = s({
  fontSize: 16,
  fontWeight: 600,
  color: "#1679ED",
  textDecoration: "none",
});

export const darkBackground = s({
  backgroundColor: Style.colors.white2,
});
